<template>
  <div class="auth-container">
    <div class="w-100 h-100">
      <div class="row m-2 h-100 justify-content-center">
        <div class="col-10 col-md-5 col-lg-3 align-self-center">
          <div
            class="w-100 d-flex flex-column justify-content-center align-items-center text-white"
          >
            <p class="m-0 font-large font-weight-bolder">Forget password</p>
            <div>
              <!-- <p class="m-0 mt-2 font-smaller">
                We heard that you lost your password. Sorry about that!
              </p> -->
              <p class="m-0 mt-2 font-smaller">
                Please enter your email address.
              </p>
            </div>
          </div>
          <div class="w-100 mt-2">
            <form id="auth-form" @submit.prevent="sendEmail">
              <div class="form-group mb-1">
                <input
                  type="email"
                  class="form-control"
                  v-model="models.identifier"
                  id="auth-email"
                  :class="{ 'border-danger': errors.identifier }"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                <label class="form-control-label" for="email">Email</label>
              </div>
              <button type="submit" class="btn w-100">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  v-if="isProcessing"
                ></span>
                <span v-if="!isProcessing">Send Email</span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/api.js";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      isProcessing: false,
      models: {
        identifier: null
      },

      errors: {
        identifier: null
      }
    };
  },
  methods: {
    async sendEmail() {
      this.isProcessing = true;
      const hasError = this.verifyForm();
      if (hasError) {
        console.log("has error");
        this.isProcessing = false;
      } else {
        console.log(this.models.identifier);
        let email = this.models.identifier;
        API.post("auth/forgot-password", {
          email: this.models.identifier
        })
          .then(res => {
            console.log(res.data);
            this.isProcessing = false;
            // if(res.data){
            Swal.fire({
              title: "Success",
              text: "Email successfully send to " + email,
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK"
            }).then(result => {
              if (result.isConfirmed) {
                this.$router.push({
                  name: "Auth"
                });
              }
            });
            // }
          })
          .catch(err => {
            console.log(err.response);
            this.isProcessing = false;

            Swal.fire(
              "User not found",
              "We cannot find user with email " + email,
              "error"
            );
          });
      }
    },
    verifyForm() {
      this.loginError = false;
      for (let index = 0; index < Object.keys(this.models).length; index++) {
        const key = Object.keys(this.models)[index];
        const model = this.models[key];

        if (!model) {
          this.errors[key] = true;
          this.loginError = true;
        } else {
          this.errors[key] = false;
        }
      }

      return this.loginError;
    }
  }
};
</script>